import React, { useState, useEffect } from "react";
import Loader from "../../components/loader";
import Cookies from "js-cookie";
import "../../styles/main.sass";

function DashboardLogin() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (Cookies.get("cmac")) {
      console.log("si");
      window.location.href = "/dashboard/";
    } else {
      setLoading(false);
    }
  }, []);

  function passwordCheck(event) {
    event.preventDefault();
    const username = event.target[0].value;
    const password = event.target[1].value;

    if (
      username === process.env.GATSBY_LOGIN_USERNAME &&
      password === process.env.GATSBY_LOGIN_PASSWORD
    ) {
      console.log("mathch");
      setError(false);
      Cookies.set(
        "cmac",
        "n1js9d10sjd871n8d7sjs89asdj19sd1h827e120dsha7ds6ads5d1927d6sd235s7dagsdò@asdu"
      );
      window.location.reload();
    } else {
      setError(true);
    }
  }

  if (loading) return <Loader></Loader>;

  return (
    <div className="login-w">
      <form onSubmit={passwordCheck}>
        <h1>Dashboard</h1>
        <input type="text" placeholder="Nome utente" />
        <input type="password" placeholder="Password" />
        {error && (
          <div style={{ color: "red" }}>email o password non validi</div>
        )}
        <button>Accedi</button>
      </form>
    </div>
  );
}

export default DashboardLogin;
